import Footer from './footer'
import Header from './header'

export const MainLayout = ({ children }: HocProps) => {
  return (
    <>
      <div className='min-h-[100vh] bg-gradient-to-b from-primary-bg-light to-primary-bg-dark overflow-x-clip'>
        <Header />
        { children }
      </div>
      <Footer />
    </>
  )
}
