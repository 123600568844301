import React from 'react'
import { motion } from 'framer-motion'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import Image from 'next/image'

const Coin = ({
  position,
  delay,
  duration,
}: {
  position: number
  delay: number
  duration: number
}) => {
  const variants = {
    start: {
      y: '-50vh',
    },
    end: {
      y: '100vh',
    },
  }

  return (
    <motion.div
      initial="start"
      animate="end"
      variants={variants}
      transition={{
        duration: duration,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'linear',
        delay: delay,
      }}
      style={{
        position: 'absolute',
        left: `${position}vw`,
      }}
    >
      <Image
        src={staticImportImage(ImageName.Coin)}
        className="z-[-100] w-[36px] h-[36px] lg:w-[75px] lg:h-[75px]"
        alt={ImageName.Coin}
      />
    </motion.div>
  )
}

export default Coin
