import Image from 'next/image'

import { Container } from '@/layouts/components'
import useTrans from '@/root/src/hooks/use-trans'
import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import {
  Box,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { TitleText } from '../../components/base/TitleText'

const defaultMenus = [
  {
    link: 'https://x.com/pikat_pikat',
    icon: ImageName.TelegramIcon,
  },
  {
    link: 'https://t.me/pikat_pikat',
    icon: ImageName.TwitterIcon,
  },
  {
    link: 'https://dexscreener.com/solana/FdPn2PAmpVUZ7emKbg8v57ii3MsakQTV5p56C4zhT6Fo',
    icon: ImageName.DexScreenerIcon,
  },
  {
    link: `https://solscan.io/token/${process.env.NEXT_PUBLIC_PIKATSO_TOKEN_ADDRESS}`,
    icon: ImageName.SolScanIcon,
  },
  {
    link: '#',
    icon: ImageName.CoinmarketcapIcon,
  },
  {
    link: '#',
    icon: ImageName.CoinGeckoIcon,
  },
  {
    link: '#',
    icon: ImageName.CryptoRankIcon,
  },
  {
    link: 'https://www.dextools.io/app/en/solana/pair-explorer/FdPn2PAmpVUZ7emKbg8v57ii3MsakQTV5p56C4zhT6Fo',
    icon: ImageName.DexToolsIcon,
  },
]
const Footer = () => {
  const trans = useTrans()
  const lgScreen = useMediaQuery('(min-width: 1024px)')

  return (
    <>
      <Box className="relative w-full">
        <div className="w-full mx-0 flex flex-col lg:gap-20 gap-9 pb-40 justify-center items-center">
        <TitleText className="lg:text-[110px] lg:leading-[110px] text-[62px] leading-[62px]">{trans.footer.title}</TitleText>
          <Container className="flex items-center justify-center gap-6">
            <Typography className="font-space-grotesk text-[16px] md:text-[30px] text-primary-text-dark">
              {trans.footer.text}
            </Typography>
            <Image
              src={staticImportImage(ImageName.Solana)}
              alt={ImageName.Solana}
              width={`${lgScreen ? '198' : '100'}`}
            />
          </Container>
          <div>
            <List className="relative flex lg:gap-6 gap-1 p-0 z-50">
              {defaultMenus.map((menu, index) => (
                <div key={index} className="relative">
                  <ListItem key={index} role="none" className="p-0 z-50">
                    <Link
                      href={menu.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={staticImportImage(menu.icon)}
                        alt={menu.icon}
                        width={`${lgScreen ? 100 : 30}`}
                      />
                    </Link>
                  </ListItem>
                  <div className="absolute flex justify-center top-[110%] w-full z-50">
                    <div className="bg-[#FF90AB] lg:w-11 lg:h-2 w-5 h-1 rounded-[50%] border-none"></div>
                  </div>
                </div>
              ))}
              <div className="absolute flex justify-center lg:top-[67%] top-[56%] lg:left-[calc(50%-527px)] left-[calc(50%-160px)] z-10">
                <div className="bg-[#FFC5D2] lg:w-[1054px] lg:h-[94px] w-[320px] h-[36px] rounded-[50%] border-none"></div>
              </div>
            </List>
          </div>
        </div>
        <Image
          className="absolute bottom-0 w-full left-0 z-[-1]"
          src={staticImportImage(ImageName.FooterBackground)}
          alt={ImageName.FooterBackground}
          height={`${lgScreen ? '1000' : '760'}`}
        />
        <div className="absolute bottom-0 left-0 text-center w-full py-4">
          <Typography className="font-space-grotesk opacity-60 text-[#494949] text-[16px]">
            {trans.footer.copy_right}
          </Typography>
        </div>
      </Box>
    </>
  )
}

export default Footer
