import AboutImage from '@/assets/about-image.png'
import AboutTitleFrame from '@/assets/about-title-frame.png'
import BottomLineBackGround from '@/assets/bottom-line-bg.svg'
import BottomLine from '@/assets/bottom-line.png'
import BuyIcon from '@/assets/buy-icon.png'
import CollectionImg1 from '@/assets/collection-img-1.png'
import CollectionImg2 from '@/assets/collection-img-2.png'
import CollectionImg3 from '@/assets/collection-img-3.png'
import CollectionImg4 from '@/assets/collection-img-4.png'
import CollectionImg5 from '@/assets/collection-img-5.png'
import CollectionImg6 from '@/assets/collection-img-6.png'
import CollectionImg7 from '@/assets/collection-img-7.png'
import CollectionImg8 from '@/assets/collection-img-8.png'
import FooterBackground from '@/assets/footer_background.png'
import SectionLine from '@/assets/line.png'
import MainLogo from '@/assets/main_logo.png'
import PikatsoAstronault from '@/assets/pikatso-astronault.png'
import PikatsoRocket from '@/assets/pikatso-rocket.png'
import PlayVideoButton from '@/assets/play-icon.svg'
import Solana from '@/assets/solana.png'
import Telegram from '@/assets/telegram.png'
import TextFooter from '@/assets/text_footer.png'
import TelegramIcon from '@/assets/telegram-icon.png'
import TwitterIcon from '@/assets/twitter-icon.png'
import DexScreenerIcon from '@/assets/dex-screener-icon.png'
import SolScanIcon from '@/assets/solscan-icon.png'
import CoinmarketcapIcon from '@/assets/coinmarketcap-icon.png'
import CoinGeckoIcon from '@/assets/coingecko-icon.png'
import CryptoRankIcon from '@/assets/cryptorank-icon.png'
import DexToolsIcon from '@/assets/dextools-icon.png'
import Coin from '@/assets/coin.png'
import CopyIcon from '@/assets/copy-icon.png'
import PikatsoHero from '@/assets/pikatso_hero.png'
import HeroShadow from '@/assets/hero_shadow.png'
import Twitter from '@/assets/twitter.png'
import Tokenomics from '@/assets/tokenomics.png'
import RenounceCat from '@/assets/renounce_cat.png'
import BurntCat from '@/assets/burnt_cat.png'

export const ImageName = {
  MainLogo: 'main_logo',
  Twitter: 'twitter',
  Telegram: 'telegram',
  BuyIcon: 'buy_icon',
  FooterBackground: 'footer_background',
  TextFooter: 'text_footer',
  Solana: 'solana',
  TelegramIcon: 'telegram-icon',
  TwitterIcon: 'twitter-icon',
  DexScreenerIcon: 'dex-screener-icon',
  SolScanIcon: 'solscan-icon',
  CoinmarketcapIcon: 'coinmarketcap-icon',
  CoinGeckoIcon: 'coingecko-icon',
  CryptoRankIcon: 'cryptorank-icon',
  DexToolsIcon: 'dextools-icon',
  Coin: 'coin',
  CopyIcon: 'copy-icon',
  PikatsoHero: 'pikatso_hero',
  HeroShadow: 'hero_shadow',
  AboutImage: 'about_image',
  AboutTitleFrame: 'about_title_frame',
  PlayVideoButton: 'play_video_button',
  SectionLine: 'section_line',
  PikatsoRocket: 'pikatso_rocket',
  PikatsoAstronault: 'pikatso_astronault',
  Tokenomics: 'tokenomics',
  RenounceCat: 'renounce_cat',
  BurntCat: 'burnt_cat',
  CollectionImg1: 'collection_img_1',
  CollectionImg2: 'collection_img_2',
  CollectionImg3: 'collection_img_3',
  CollectionImg4: 'collection_img_4',
  CollectionImg5: 'collection_img_5',
  CollectionImg6: 'collection_img_6',
  CollectionImg7: 'collection_img_7',
  CollectionImg8: 'collection_img_8',
  BottomLine: 'bottom_line',
  BottomLineBackGround: 'bottom_line_bg',
  ButtonIcon: ' button_icon',
}

export const staticImportImage = (iconName: string) => {
  switch (iconName) {
    case ImageName.MainLogo:
      return MainLogo
    case ImageName.Twitter:
      return Twitter
    case ImageName.Telegram:
      return Telegram
    case ImageName.BuyIcon:
      return BuyIcon
    case ImageName.FooterBackground:
      return FooterBackground
    case ImageName.TextFooter:
      return TextFooter
    case ImageName.Solana:
      return Solana
    case ImageName.TelegramIcon:
      return TelegramIcon
    case ImageName.TwitterIcon:
      return TwitterIcon
    case ImageName.DexScreenerIcon:
      return DexScreenerIcon
    case ImageName.SolScanIcon:
      return SolScanIcon
    case ImageName.CoinmarketcapIcon:
      return CoinmarketcapIcon
    case ImageName.CoinGeckoIcon:
      return CoinGeckoIcon
    case ImageName.CryptoRankIcon:
      return CryptoRankIcon
    case ImageName.DexToolsIcon:
      return DexToolsIcon
    case ImageName.Coin:
      return Coin
    case ImageName.CopyIcon:
      return CopyIcon
    case ImageName.PikatsoHero:
      return PikatsoHero
    case ImageName.HeroShadow:
      return HeroShadow
    case ImageName.AboutImage:
      return AboutImage
    case ImageName.AboutTitleFrame:
      return AboutTitleFrame
    case ImageName.PlayVideoButton:
      return PlayVideoButton
    case ImageName.SectionLine:
      return SectionLine
    case ImageName.PikatsoRocket:
      return PikatsoRocket
    case ImageName.PikatsoAstronault:
      return PikatsoAstronault
    case ImageName.Tokenomics:
      return Tokenomics
    case ImageName.RenounceCat:
      return RenounceCat
    case ImageName.BurntCat:
      return BurntCat
    case ImageName.CollectionImg1:
      return CollectionImg1
    case ImageName.CollectionImg2:
      return CollectionImg2
    case ImageName.CollectionImg3:
      return CollectionImg3
    case ImageName.CollectionImg4:
      return CollectionImg4
    case ImageName.CollectionImg5:
      return CollectionImg5
    case ImageName.CollectionImg6:
      return CollectionImg6
    case ImageName.CollectionImg7:
      return CollectionImg7
    case ImageName.CollectionImg8:
      return CollectionImg8
    case ImageName.BottomLine:
      return BottomLine
    case ImageName.BottomLineBackGround:
      return BottomLineBackGround
    default:
      return MainLogo
  }
}
