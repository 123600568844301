import { motion } from 'framer-motion'
import Image from 'next/image'
import { flyIn } from 'shared-utilities'

import { TitleText } from '@/components/base/TitleText'
import { Container as LayoutContainer } from '@/layouts/components'
import useTrans from '@/root/src/hooks/use-trans'
import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import {
  Stack,
  Typography,
} from '@mui/material'

import { Button } from '../../base/Button'

const Tokenomics = () => {
  const trans = useTrans()

  return (
    <>
      <LayoutContainer>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.15 }}
          variants={{ ...flyIn({ direction: 'right', duration: 1 }) }}
        >
          <Stack className="flex-column relative py-20">
            <Stack className="flex-column items-center gap-[56px] lg:gap-[72px]" id="tokenomics">
              <TitleText className="text-[62px] leading-[62px] lg:text-[110px] lg:leading-[110px]">
                {trans.tokenomics.title}
              </TitleText>
              <Stack className="flex-column items-center gap-[16px] lg:gap-[24px]">
                <Typography className="font-space-grotesk text-primary-text-dark text-[28px] leading-[32px] lg:text-[36px] lg:leading-[40px]">
                  {trans.tokenomics.total_supply}
                </Typography>
                <TitleText className="text-[34px] leading-[34px] lg:text-[72px] lg:leading-[72px]">
                  {trans.tokenomics.total_supply_value}
                </TitleText>
              </Stack>
              <Stack className="relative">
                <Image
                  className="w-[272px] h-[272px] lg:w-[550px] lg:h-[550px]"
                  src={staticImportImage(ImageName.Tokenomics)}
                  alt={ImageName.Tokenomics}
                />
                <Stack className="absolute flex-column top-[-10px] left-[-20px] lg:top-[calc(50%-52px)] lg:left-[-140px]">
                  <TitleText className="text-[34px] leading-[34px] lg:text-[72px] lg:leading-[72px]">
                    {trans.tokenomics.liquidity_value}
                  </TitleText>
                  <Typography className="font-space-grotesk text-primary-text-dark text-[16px] leading-[20px] lg:text-[24px] lg:leading-[32px]">
                    {trans.tokenomics.liquidity}
                  </Typography>
                </Stack>
                <Stack className="absolute flex-column top-[-10px] right-[-54px] md:right-[-104px] lg:right-[-170px]">
                  <TitleText className="text-[34px] leading-[34px] lg:text-[72px] lg:leading-[72px]">
                    {trans.tokenomics.product_value}
                  </TitleText>
                  <Typography className="font-space-grotesk lg:max-w-[200px] max-w-[120px] text-primary-text-dark text-[16px] leading-[20px] lg:text-[24px] lg:leading-[32px]">
                    {trans.tokenomics.product_development_and_marketing}
                  </Typography>
                </Stack>
                <Stack className="absolute flex-column bottom-[10px] lg:bottom-[40px] right-[-10px] md:right-[-40px] lg:right-[-120px]">
                  <TitleText className="text-[34px] leading-[34px] lg:text-[72px] lg:leading-[72px]">
                    {trans.tokenomics.airdrop_value}
                  </TitleText>
                  <Typography className="font-space-grotesk lg:max-w-[200px] max-w-[120px] text-primary-text-dark text-[16px] leading-[20px] lg:text-[24px] lg:leading-[32px]">
                    {trans.tokenomics.airdrop}
                  </Typography>
                </Stack>
              </Stack>
              <Stack className="w-full flex-column justify-center items-center lg:flex-row gap-4 text-[18px] lg:text-[24px]">
                <Button className="bg-[#F29DB8] rounded-2xl w-full flex gap-2 max-w-[400px]">
                  <Image
                    className="w-[36px] h-[42px] lg:w-[44px] lg:h-[50px]"
                    src={staticImportImage(ImageName.RenounceCat)}
                    alt={ImageName.RenounceCat}
                  />
                  <Typography className="font-bold normal-case text-secondary-text-dark font-space-grotesk">
                    {trans.tokenomics.contract_renounced}
                  </Typography>
                </Button>
                <Button className="bg-[#F29DB8] rounded-2xl w-full flex max-w-[400px]">
                  <Image
                    className="w-[73px] h-[49px] lg:w-[85px] lg:h-[57px]"
                    src={staticImportImage(ImageName.BurntCat)}
                    alt={ImageName.BurntCat}
                  />
                  <Typography className="font-bold normal-case text-secondary-text-dark font-space-grotesk">
                    {trans.tokenomics.liquidity_burnt}
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </motion.div>
      </LayoutContainer>
      <Image src={staticImportImage(ImageName.SectionLine)} alt={ImageName.SectionLine} className='w-full mt-[80px]'/>
    </>
  )
}

export default Tokenomics
