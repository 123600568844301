import type {
  CSSProperties,
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react'

import { Button as MuiButton } from '@mui/material'

export type ButtonProps = PropsWithChildren<{
  className?: string
  disabled?: boolean
  endIcon?: ReactElement
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  beginIcon?: ReactElement
  style?: CSSProperties
  tabIndex?: number
  listMenuItems?: ReactNode
  onlyAddress?: boolean
  hideButton?: boolean
  href?: string
  target?: string
  rel?: string
}>

export const Button: FC<ButtonProps> = (props) => {
  const buttonProps = { ...props }
  Object.keys(buttonProps).forEach(key => key === 'beginIcon' && delete buttonProps[key])

  return (
    <MuiButton
      className={`wallet-adapter-button ${props.className || ''}`}
      disabled={props.disabled}
      style={props.style}
      onClick={props.onClick}
      tabIndex={props.tabIndex || 0}
      type="button"
      {...buttonProps}
    >
      {props.beginIcon && <i className="wallet-adapter-button-start-icon">{props.beginIcon}</i>}
      {props.children}
      {props.endIcon && <i className="wallet-adapter-button-end-icon">{props.endIcon}</i>}
    </MuiButton>
  )
}
