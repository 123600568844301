import React, { memo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Paper, Stack } from '@mui/material'

import BuyPikaButton from './buy_pika_button'
import { SocialsButtonsBase } from 'shared-components'
import MainLogo from './main_logo'
import SocialsButtons from './socials_buttons'

const defaultMenus = [
  {
    name: 'about',
    link: '#about',
  },
  {
    name: 'tokenomics',
    link: '#tokenomics',
    rounded: true,
  },
  {
    name: 'collections',
    link: '#collections',
  },
]

type MainMenuProps = {
  toggleDrawer: (
    open: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => void
}

const MainMenu = (props: MainMenuProps) => {
  const { toggleDrawer } = props

  return (
    <Stack
      className="bg-gradient-to-b from-primary-bg-light to-primary-bg-dark h-full w-full border-none z-[9999]"
      justifyContent="space-between"
      role="presentation"
    >
      <Paper elevation={0} className="bg-transparent">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          justifyItems="center"
          className="w-full p-3"
        >
          <MainLogo />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className="sm:w-fit"
          >
            <BuyPikaButton size="xs" />
            <IconButton
              className="text-white mr-2 p-1"
              aria-label="open menu"
              edge="start"
              onClick={toggleDrawer(false)}
            >
              <CloseIcon className="text-[#2E1647]" fontSize="medium" />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
      <Stack justifyContent="space-between" alignItems="center" className="h-full pt-8">
        <SocialsButtons className="flex mb-4" />
        <SocialsButtonsBase
          classBox="flex w-full px-4"
          classContainer="flex flex-col lg:flex-row p-0 lg:justify-center justify-start items-center gap-2 w-full"
          classButtons="w-full justify-center px-4 py-2.5 uppercase bg-white text-primary-text-dark font-normal font-space-grotesk hover:text-ps-secondary text-base p-0"
          buttons={defaultMenus}
          closeDrawer={toggleDrawer(false)}
        />
      </Stack>
    </Stack>
  )
}

export default memo(MainMenu)
