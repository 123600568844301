import React, { useCallback, useMemo, useState } from 'react'

import { Container } from '@/layouts/components'
import MainMenu from '@/root/src/components/base/main_menu'
import MainLogo from '@/root/src/components/base/main_logo'
import { SocialsButtonsBase } from 'shared-components'
import SocialsButtons from '@/root/src/components/base/socials_buttons'
import { Box, SwipeableDrawer } from '@mui/material'
import PikaTokenAction from '../../components/base/pika_token_action'
const defaultMenus = [
  {
    name: 'about',
    link: '#about',
  },
  {
    name: 'tokenomics',
    link: '#tokenomics',
    rounded: true,
  },
  {
    name: 'collections',
    link: '#collections',
  },
]

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setIsOpenMenu(open)
    },
    [],
  )

  const handleClickMenu = useCallback(() => {
    setIsOpenMenu(true)
  }, [setIsOpenMenu])

  const actionProps = useMemo(() => {
    return { handleClickMenu }
  }, [handleClickMenu])

  const drawer = useMemo(
    () => (
      <SwipeableDrawer
        anchor="right"
        open={isOpenMenu}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={15}
        hysteresis={1}
        className="z-[99999]"
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
          },
        }}
      >
        <MainMenu toggleDrawer={toggleDrawer} />
      </SwipeableDrawer>
    ),
    [isOpenMenu, toggleDrawer],
  )

  return (
    <Box className="md:py-8 bg-[transparent] relative w-full z-[9999] py-4">
      <Container className="flex justify-between items-center">
        <MainLogo />
        <SocialsButtonsBase
          classBox="lg:flex hidden"
          classContainer="flex flex-col lg:flex-row p-0 lg:justify-center justify-start items-center gap-2 w-full"
          classButtons="justify-center px-4 py-2.5 uppercase bg-white text-primary-text-dark font-normal font-space-grotesk hover:text-ps-secondary text-base p-0"
          buttons={defaultMenus}
        />
        <SocialsButtons className="lg:flex hidden" />
        <PikaTokenAction {...actionProps} />
      </Container>
      {drawer}
    </Box>
  )
}

export default Header
