import { memo } from 'react'

import Image from 'next/image'

import { trackAmplitude } from 'shared-utilities'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import { Stack, Typography } from '@mui/material'

import { Button } from './Button'

interface BuyPikaButtonProps {
  size?: 'xs' | 'md'
  fullWidth?: boolean
}

const BuyPikaButton = ({ size = 'md', fullWidth }: BuyPikaButtonProps) => {
  const widthClass = fullWidth ? 'w-full sm:w-fit' : 'w-fit'
  const paddingClass = size === 'xs' ? 'py-2 px-4' : 'p-6'
  const textContent = size === 'xs' ? 'Buy PIKA' : 'Buy on Raydium'
  const textSize = size === 'xs' ? 'text-sm' : 'text-2xl'
  const iconSize = size === 'xs' ? 24 : 32
  const handleClick = () => {
    trackAmplitude('click_buy_pikat_button', { product: 'pikasto' }, { location: 'main_menu' })
  }

  return (
    <Button
      className={`bg-white normal-case ${widthClass} border-[2px] rounded-lg ${paddingClass} border-primary-text-dark border-solid shadow-[0px_5px_0px_0px_#2D0E35]`}
      href="https://raydium.io/swap/?inputMint=sol&outputMint=A4Vt8QKdNrxikW46vjn3HmE3rDzUFD8ECAYkijvqi9hw"
      target="_blank"
      rel="noreferrer noopener"
      onClick={handleClick}
    >
      <Stack className="flex-row justify-between items-center gap-4">
        <Image src={staticImportImage(ImageName.BuyIcon)} alt="buy_icon" width={iconSize} />
        <Typography className={`font-bold ${textSize} text-primary-text-dark`}>
          {textContent}
        </Typography>
      </Stack>
    </Button>
  )
}

export default memo(BuyPikaButton)
