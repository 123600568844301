import { useEffect } from 'react'

import dynamic from 'next/dynamic'

import { About, Swap, Hero, Tokenomics } from '@/components/home/components'
import { trackAmplitude } from 'shared-utilities'

const Collection = dynamic(() => import('@/components/home/components/collection'), { ssr: false })

const Home = () => {
  useEffect(() => {
    trackAmplitude('visit_home_page', { product: 'pikatso' })
  }, [])

  return (
    <>
      <Hero />
      <About />
      <Tokenomics />
      <Swap />
      <Collection />
    </>
  )
}

export default Home
