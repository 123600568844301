import {
  useCallback,
  useState,
} from 'react'

import { motion } from 'framer-motion'
import Image from 'next/image'
import { flyIn } from 'shared-utilities'

import { TitleText } from '@/components/base/TitleText'
import { Container as LayoutContainer } from '@/layouts/components'
import useTrans from '@/root/src/hooks/use-trans'
import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import {
  Box,
  Dialog,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

interface VideoDialogProps {
  isOpen: boolean
  onClose: () => void
}

const VideoDialog = ({ isOpen, onClose }: VideoDialogProps) => {
  const isSmallScreen = useMediaQuery('(max-width: 640px)')
  const videoRefCallback = useCallback((elm: HTMLVideoElement) => {
    if (!elm) return

    elm.addEventListener('play', () => {
      if (isSmallScreen && elm.requestFullscreen) {
        elm.requestFullscreen()
      }
    })
  }, [isSmallScreen])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false}
    >
      <video
        autoPlay
        controls
        loop
        preload="auto"
        className="w-full max-h-[80dvh]"
        ref={videoRefCallback}
      >
        <source src="https://d2gq1cj4b4atj8.cloudfront.net/assets/pikatso-trailer.mp4" type="video/mp4" />
      </video>
    </Dialog>
  )
}

const About = () => {
  const [openVideoDialog, setOpenVideoDialog] = useState(false)
  const trans = useTrans()

  return (
    <>
      <LayoutContainer className="xl:w-[1100px] py-28">
        <Stack className="flex-column md:flex-row gap-8 lg:gap-12 items-center justify-center 2xl:gap-32" id="about">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.15}}
            variants={{
              ...flyIn({ direction: 'right', duration: 1 }),
            }}
          >
            <Stack className="flex-column gap-6 justify-center items-center xl:gap-14 xl:max-w-[300px]">
              <TitleText className="text-[62px] leading-[62px] md:text-[110px] md:leading-[110px]">{trans.about.title}</TitleText>
              <Typography className="text-dark-purple-shade font-[500] text-[24px] leading-[40px]">
                <span className="font-bold text-white bg-strong-reddish-pink">{trans.about.content_1}</span>
                <span className="line-through">{trans.about.content_2}</span>
                <br />
                <span className="font-bold text-white bg-strong-reddish-pink">{trans.about.content_3}</span>
                <span className="line-through">{trans.about.content_4}</span>
              </Typography>
            </Stack>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.15}}
            variants={{
              ...flyIn({ direction: 'left', duration: 1 }),
            }}
          >
            <Stack className="flex-column gap-[6px] justify-center items-center relative">
              <Link onClick={() => setOpenVideoDialog(true)}>
                <Box className="relative hover:cursor-pointer">
                  <Image src={staticImportImage(ImageName.AboutImage)} alt={ImageName.AboutImage} className="w-[316px] h-[290px] lg:w-[626px] lg:h-[580px]" />
                  <Image
                    src={staticImportImage(ImageName.PlayVideoButton)}
                    alt={ImageName.PlayVideoButton}
                    className="w-[58px] h-[80px] absolute right-[calc(50%-29px)] top-[calc(50%-40px)] opacity-85 hover:cursor-pointer hover:opacity-65"
                    onClick={() => setOpenVideoDialog(true)} />
                </Box>
              </Link>
              <Box className="relative lg:-top-[60px]">
                <Image
                  src={staticImportImage(ImageName.AboutTitleFrame)}
                  alt={ImageName.AboutTitleFrame}
                  className="w-[262px] h-[64px] lg:w-[522.5px] lg:h-[126.5px]"
                />
                <Typography
                  className="text-primary-text font-lilita text-xl lg:text-[44px] lg:leading-[48px] absolute -rotate-[4deg] left-[calc(50%-94px)] top-[calc(50%-20px)] lg:left-[calc(50%-184px)] lg:top-[calc(50%-35px)]"
                >
                  {trans.about.slogan}
                </Typography>
              </Box>
            </Stack>
          </motion.div>
        </Stack>
        <VideoDialog isOpen={openVideoDialog} onClose={() => setOpenVideoDialog(false)} />
      </LayoutContainer>
      <Image src={staticImportImage(ImageName.SectionLine)} alt={ImageName.SectionLine} className='w-full'/>
    </>
  )
}

export default About
