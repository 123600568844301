import React from 'react'

import Image from 'next/image'

import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Link } from '@mui/material'

const MainLogo = () => {
  return (
    <Link href="/" data-testid="header-home-trade" className="no-underline z-10">
      <Image
        className="inline lg:w-[140px] lg:h-[54px] w-[78px] h-[30px]"
        src={staticImportImage(ImageName.MainLogo)}
        alt={ImageName.MainLogo}
      />
    </Link>
  )
}

export default MainLogo
