import { memo, useMemo } from 'react'

import Image from 'next/image'
import { trackAmplitude } from 'shared-utilities'

import useTrans from '@/root/src/hooks/use-trans'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { List, ListItem, ListItemButton } from '@mui/material'

const SocialsButtons = ({ className }: { className: any }) => {
  const trans = useTrans()

  const defaultMenus = useMemo(() => {
    return [
      {
        link: 'https://x.com/pikat_pikat',
        icon: ImageName.Twitter,
        name: 'twitter',
      },
      {
        link: 'https://t.me/pikat_pikat',
        icon: ImageName.Telegram,
        name: 'telegram',
      },
    ]
  }, [])
  const handleClickSocial = (name?: string) => {
    if (!name) return

    trackAmplitude('click_social_link', { product: 'pikatso' }, { social: name })
  }

  return (
    <List
      role="menubar"
      className={`justify-between uppercase gap-4 md:mb-6 lg:mb-0 sm:justify-center md:gap-6 w-full md:w-fit ${className}`}
    >
      {defaultMenus.map((menu, index) => (
        <ListItem key={index} role="none" className="sm:w-fit flex justify-center px-0 py-0">
          <ListItemButton
            role="menuitem"
            disableRipple={true}
            className="max-h-[24px] text-primary-text-dark gap-2 justify-center items-center sm:bg-transparent rounded-lg font-normal hover:text-ps-secondary text-base normal-case p-0"
            component="a"
            target="_blank"
            onClick={() => handleClickSocial(menu.name)}
            href={menu.link}
          >
            <Image src={staticImportImage(menu.icon)} alt={menu.icon} width={18} />
            {trans.header[menu.name]}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default memo(SocialsButtons)
