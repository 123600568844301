import { memo } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import {
  IconButton,
  Stack,
} from '@mui/material'

import BuyPikaButton from './buy_pika_button'

interface PikaTokenActionProps {
  handleClickMenu: () => void
}

const PikaTokenAction = (props: PikaTokenActionProps) => {
  const { handleClickMenu } = props

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      className="sm:w-fit lg:hidden flex"
    >
      <BuyPikaButton size='xs' />
      <IconButton
        className="block lg:hidden text-[#2E1647] mr-2 rounded-lg p-3/2"
        aria-label="open menu"
        edge="start"
        disableRipple
        onClick={handleClickMenu}
      >
        <MenuIcon fontSize="medium" />
      </IconButton>
    </Stack>
  )
}

export default memo(PikaTokenAction)
