import { motion } from 'framer-motion'
import Image from 'next/image'
import {
  floating,
  flyIn,
} from 'shared-utilities'
import {
  JupiterSwapForm,
  useJupiterTerminalScript,
} from 'swap-form'

import { TitleText } from '@/components/base/TitleText'
import useTrans from '@/hooks/use-trans'
import { Container as LayoutContainer } from '@/layouts/components'
import {
  ImageName,
  staticImportImage,
} from '@/utils/static-import-image'
import {
  Box,
  Stack,
} from '@mui/material'

const Swap = () => {
  const trans = useTrans()
  const { scriptLoaded, scriptLoadError } = useJupiterTerminalScript()

  return (
    <>
      <Box className="relative" id="swap">
        <LayoutContainer className="max-sm:min-h-[120dvh] min-h-[100dvh] pt-[120px] pb-[200px] lg:mb-[200px] relative z-50">
          <Stack className="flex-column items-center gap-8">
            <TitleText className="leading-[62px] text-[72px] md:text-[110px] md:leading-[110px] font-normal">{trans.swap.title}</TitleText>
            { !scriptLoadError && (
              <JupiterSwapForm
                scriptLoaded={scriptLoaded}
                endpoint={process.env.NEXT_PUBLIC_SOLANA_ENDPOINT || ''}
                outputMint={process.env.NEXT_PUBLIC_PIKATSO_TOKEN_ADDRESS || ''}
                containerStyles={{
                  backgroundColor: '#304256',
                  padding: '1.25rem',
                  borderRadius: '1rem',
                  maxWidth: '500px',
                }}
              />
            )}
          </Stack>
        </LayoutContainer>
        <motion.div
          className="absolute top-0 left-0 w-full min-h-[100dvh] z-40"
          initial="hidden"
          whileInView={['visible', 'floating']}
          viewport={{ once: true, amount: 0.15}}
          variants={{
            ...flyIn({ direction: 'up', duration: 1 }),
            ...floating({ axis: 'y', offset: 10, duration: 1.5, delay: 0.5 }),
          }}
        >
          <Image
            src={staticImportImage(ImageName.PikatsoRocket)}
            alt={ImageName.PikatsoRocket}
            className="w-[124px] h-[160px] lg:w-[249px] lg:h-[315px] relative -top-[35px] -left-[20px] xl:top-[120px] xl:left-[100px]"
          />
          <Image
            src={staticImportImage(ImageName.PikatsoAstronault)}
            alt={ImageName.PikatsoAstronault}
            className="w-[250px] h-[230px] lg:w-[422px] lg:h-[385px] relative max-sm:top-[calc(100dvh-50px)] top-[calc(100dvh-150px)] left-[calc(100vw-300px)] lg:left-[calc(90vw-500px)] lg:top-[calc(100dvh-200px)]"
          />
        </motion.div>
      </Box>
      <Image src={staticImportImage(ImageName.SectionLine)} alt={ImageName.SectionLine} className='w-full mt-[80px]'/>
    </>
  )
}

export default Swap
