import { ReactElement } from 'react'

import { Typography } from '@mui/material'

interface TitleTextProps {
  children: ReactElement | ReactElement[] | string
  className?: string
}

export const TitleText = ({ children, className }: TitleTextProps) => {
  return (
    <Typography
      className={`text-primary-text font-lilita font-normal lg:text-shadow-title text-shadow-mobile ${className}`}
      sx={{
        '-webkit-text-stroke': '1.5px rgba(39, 35, 40, 1)',
      }}
    >
      {children}
    </Typography>
  )
}
