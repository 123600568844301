// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  seo: {
    title: 'Pikatso Token',
    description: 'Discover Pikatso, the trendsetting cat-themed memecoin with $PIKAT tokens. Join our vibrant community, harness meme magic, and revolutionize crypto with feline flair.',
    thumbnailUrl: '/images/thumbnail.png',
  },
  header: {
    about: 'About',
    tokenomics: 'Tokenomics',
    collections: 'Collections',
    twitter: 'Twitter',
    telegram: 'Telegram',
    buy_pika: 'Buy PIKA',
  },
  about: {
    title: 'About',
    content_1: 'Pikatso is ',
    content_2: 'more than',
    content_3: ' just an elegant cat.',
    content_4: ' It represents a leap forward into the meme culture and financial freedom in crypto world. Clearly, the future is for those forward-thinkers who embrace $PIKAT, breaking barriers and welcoming a new era in finance and technology.',
    slogan: "Pikatso's Showtime",
  },
  swap: {
    title: 'Swap now',
  },
  collection: {
    support: 'Love PIKATSO and want to support it?',
  },
  footer: {
    title: 'Our partners',
    text: 'Powered by',
    copy_right: '2024 PIKATSO All Rights Reserved',
  },
  hero: {
    title: 'PIKATSO',
    name: 'Pikatso ',
    title1: 'is more than just a fashionable feline with its own',
    title2: '$PIKAT token crafted by and dedicated to the memecoin community.',
    title3: 'Harness its meme magic now and let $PIKAT lead the way!',
    contract: 'Contract Address:',
    copy: 'Copy',
    buy_on_raydium: 'Buy on Raydium',
  },
  tokenomics: {
    title: 'Tokenomics',
    total_supply: 'Total supply',
    total_supply_value: '100,000,000 $PIKAT',
    liquidity: 'Liquidity',
    liquidity_value: '50%',
    product_development_and_marketing: 'Product Development and Marketing',
    product_value: '20%',
    airdrop: 'Airdrop',
    airdrop_value: '30%',
    contract_renounced: 'Contract renounced',
    liquidity_burnt: 'Liquidity burnt',
  },
}

export default locale
