import { motion } from 'framer-motion'
import Image from 'next/image'
import {
  floating,
  flyIn,
} from 'shared-utilities'

import BuyPiKaButton from '@/components/base/buy_pika_button'
import useTrans from '@/hooks/use-trans'
import { Container as LayoutContainer } from '@/layouts/components'
import {
  ImageName,
  staticImportImage,
} from '@/utils/static-import-image'
import {
  Box,
  Stack,
  Typography,
} from '@mui/material'

const COLLECTION_IMAGES = [
  ImageName.CollectionImg1,
  ImageName.CollectionImg2,
  ImageName.CollectionImg3,
  ImageName.CollectionImg4,
  ImageName.CollectionImg5,
  ImageName.CollectionImg6,
  ImageName.CollectionImg7,
  ImageName.CollectionImg8,
]

const renderImages = (imageArray: string[]) => (
  imageArray.map((imageName, index) => {
    const delay = Math.random() * (1 - 0.25) + 0.25
    const offset = Math.random() * (15 - 5) + 5
    const duration = Math.random() * (1.5 - 0.5) + 0.5
    const hiddenClass = index > 1 ? 'hidden sm:block' : ''

    return (
      <motion.div
        key={index}
        initial="hidden"
        whileInView={['visible', 'floating']}
        viewport={{ once: true, amount: 0.15 }}
        variants={{
          ...flyIn({ direction: 'up', duration: 1 }),
          ...floating({ axis: 'y', offset, duration , delay }),
        }}
        className={`max-w-[140px] xl:max-w-[280px] ${hiddenClass}`}
      >
        <Image src={staticImportImage(imageName)} alt={imageName} className="w-[155.47px] h-[150.5px] xl:w-[257.71px] xl:h-[249.48px]" />
      </motion.div>
    )
  })
)

const Collection = () => {
  const trans = useTrans()
  const shuffledImages = COLLECTION_IMAGES.sort(() => Math.random() - 0.5)
  const firstImages = shuffledImages.slice(0, 4)
  const secondImages = shuffledImages.slice(4, 8)

  return (
    <>
      <LayoutContainer className='pb-[280px] pt-[150px] min-h-[95dvh]'>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.15 }}
          variants={flyIn({ direction: 'up', duration: 0.75 })}
          id="collections"
        >
          <Stack className="flex-column gap-[80px]">
            <Stack>
              {
                [firstImages, secondImages].map((imageList, index) => (
                  <Stack className="flex-row items-center justify-center max-h-[140px] xl:max-h-[230px]" key={index}>
                    {renderImages(imageList)}
                  </Stack>
                ))
              }
            </Stack>
            <Stack className="flex-column items-center gap-8">
              <Typography className="text-[24px] leading-[36px] md:text-[40px] md:leading-[50px] font-bold text-center text-primary-text">
                {trans.collection.support}
              </Typography>
              <BuyPiKaButton fullWidth />
            </Stack>
          </Stack>
        </motion.div>
      </LayoutContainer>
      <Box className="relative -top-[5dvh]">
        <Image src={staticImportImage(ImageName.BottomLine)} alt={ImageName.BottomLine} className="absolute w-full scale-x-[1.5] z-40 -top-[12dvh] left-[20dvw]"/>
        <Image src={staticImportImage(ImageName.BottomLineBackGround)} alt={ImageName.BottomLineBackGround} className="absolute lg:scale-x-[2.5] lg:left-[20dvw] -top-[10dvh]"/>
      </Box>
    </>
  )
}

export default Collection
