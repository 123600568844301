import React from 'react'
import Coin from './coin'
import { useMediaQuery } from '@mui/material'

const CoinFall = () => {
  const lgScreen = useMediaQuery('(min-width: 1536px)')
  const coins = Array.from({ length: lgScreen ? 10 : 7 }, () => [])

  return (
    <>
      {coins.map((_, index) => (
        <Coin
          key={index}
          position={(index / coins.length + Math.random() * (index / coins.length)) * 100}
          delay={Math.random() * 10}
          duration={Math.random() * 7 + 5}
        />
      ))}
    </>
  )
}

export default CoinFall
