import { useEffect, useState, } from 'react';
export const useJupiterTerminalScript = () => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [scriptLoadError, setScriptLoadError] = useState(false);
    useEffect(() => {
        if (typeof document === undefined)
            return;
        if (document.getElementById('jupiter-terminal-script-tag'))
            return;
        const script = document.createElement('script');
        script.src = 'https://terminal.jup.ag/main-v2.js';
        script.id = 'jupiter-terminal-script-tag';
        script.onerror = () => setScriptLoadError(true);
        script.onload = () => setScriptLoaded(true);
        document.head.appendChild(script);
    }, []);
    return { scriptLoaded, scriptLoadError };
};
