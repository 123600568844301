import React, { useEffect, } from 'react';
import { CircularProgress, Stack, } from '@mui/material';
export const JupiterSwapForm = ({ scriptLoaded, endpoint, outputMint, containerStyles }) => {
    useEffect(() => {
        if (scriptLoaded && typeof window !== 'undefined') {
            window.Jupiter.init({
                displayMode: 'integrated',
                integratedTargetId: 'integrated-terminal',
                strictTokenList: false,
                endpoint: endpoint,
                formProps: {
                    swapMode: 'ExactIn',
                    initialOutputMint: outputMint,
                    fixedOutputMint: true,
                },
                containerStyles,
                autoConnect: true,
            });
        }
    }, [scriptLoaded, outputMint, endpoint, containerStyles]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "column", justifyContent: "center", alignItems: "center", sx: { width: '100%' } }, !scriptLoaded && React.createElement(CircularProgress, { sx: { color: '#304256' } })),
        React.createElement("div", { id: "integrated-terminal" })));
};
