import React from 'react'

import { motion } from 'framer-motion'
import Image from 'next/image'
import { fadeIn } from 'shared-utilities'

import BuyPiKaButton from '@/components/base/buy_pika_button'
import useTrans from '@/root/src/hooks/use-trans'
import { Container as LayoutContainer } from '@/root/src/layouts/components'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import CloseIcon from '@mui/icons-material/Close'
import { Container, IconButton, Link, Snackbar, Stack, Typography } from '@mui/material'

import { TitleText } from '../../base/TitleText'
import CoinFall from '../../base/coin_fall'

const Hero = () => {
  const trans = useTrans()
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const onCopy = () => {
    navigator.clipboard.writeText(process.env.NEXT_PUBLIC_PIKATSO_TOKEN_ADDRESS || '')
    setOpenSnackbar(true)
  }

  return (
    <>
      <Snackbar
        className="fixed bottom-[1rem] w-[50%] min-w-[250px]"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        message="Copied to clipboard"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnackbar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.15 }}
        variants={fadeIn({ duration: 0.25 })}
      >
        <LayoutContainer className="relative">
          <CoinFall />
          <Container className="flex justify-center mb-6 mt-10">
            <TitleText className="lg:text-[176px] lg:leading-[200px] text-[64px] leading-[74px] z-10">
              {trans.hero.title}
            </TitleText>
          </Container>
          <div className="text-center text-primary-text-dark font-bold text-4 lg:text-5 leading-24 font-space-grotesk inline relative z-50">
            <Container>
              <Typography className="inline font-bold">{trans.hero.name}</Typography>
              <Typography className="inline">{trans.hero.title1}</Typography>
            </Container>
            <Typography>{trans.hero.title2}</Typography>
            <Typography>{trans.hero.title3}</Typography>
          </div>
          <div className="flex justify-center w-full lg:mt-16 lg:mb-6 mt-24 mb-8 relative z-50">
            <BuyPiKaButton fullWidth />
          </div>
          <Stack className="flex-column lg:flex-row gap-2 px-10 py-4 items-center justify-center rounded-lg bg-white lg:bg-transparent bg-opacity-30 relative z-50">
            <Typography className="inline text-black opacity-60 w-fit">
              {trans.hero.contract}
            </Typography>
            <Typography className="text-black inline font-bold w-full lg:w-fit text-center break-words">
              <Link
                href={`https://solscan.io/token/${process.env.NEXT_PUBLIC_PIKATSO_TOKEN_ADDRESS}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-text-dark no-underline"
              >
                {process.env.NEXT_PUBLIC_PIKATSO_TOKEN_ADDRESS}
              </Link>
            </Typography>
            <Container className="flex gap-2 w-fit items-center justify-center mx-0 px-0">
              <Typography className="text-black lg:hidden">{trans.hero.copy}</Typography>
              <Image
                src={staticImportImage(ImageName.CopyIcon)}
                width={28}
                alt={ImageName.CopyIcon}
                className="hover:cursor-pointer"
                onClick={onCopy}
              />
            </Container>
          </Stack>
          <div className="relative z-10 h-[500px] w-[100vw] xl:ml-[-80px]">
            <Image
              className="absolute w-[325px] xl:w-[474px] h-[426px] xl:h-[621px] xl:bottom-[36%] xl:right-0 bottom-0 right-[calc(50%-162.5px)] z-[-1]"
              src={staticImportImage(ImageName.PikatsoHero)}
              alt={ImageName.PikatsoHero}
            />
            <Image
              className="absolute w-[539px] xl:w-[887px] xl:bottom-[24%] xl:right-[-200px] right-[calc(50%-300px)] bottom-[-10%] z-[-1]"
              src={staticImportImage(ImageName.HeroShadow)}
              alt={ImageName.HeroShadow}
            />
          </div>
        </LayoutContainer>
        <Image
          src={staticImportImage(ImageName.BottomLine)}
          alt={ImageName.BottomLine}
          className="w-full relative xl:-top-[200px]"
        />
      </motion.div>
    </>
  )
}

export default Hero
